<template>
  <div class="evgenia">
    <div class="img-block mb-14">
      <picture class="block w-full">
        <img src="/imgs/content/photos/rules.jpg" alt="Holy Chic Rules" class="block w-full">
      </picture>
    </div>

    <section>
      <div class="container mb-7">
        <p class="half-bordered-paragraph font-pt-mono text-2xl mb-6 leading-6">{{ $t('rules_subtitle_1') }}</p>
        <p class="global-text-sm text-sm mb-7 font-qanelas-light">{{ $t('rules_par_1') }}</p>

        <h2 class="font-pt-mono text-2xl mb-3">{{ $t('rules_subtitle_2') }}</h2>
        <p class="global-text-sm text-sm mb-6 font-qanelas-light">{{ $t('rules_par_2') }}</p>

        <h2 class="font-pt-mono text-2xl mb-3 leading-6" v-html="$t('rules_subtitle_3')"></h2>
        <p class="global-text-sm text-sm font-qanelas-light">{{ $t('rules_par_3') }}</p>
      </div>

      <div class="img-block mb-14">
        <picture class="block w-full">
          <img src="/imgs/content/photos/rules2.jpg" alt="Holy Chic Rules" class="block w-full">
        </picture>
      </div>

      <div class="container mb-7">
        <h2 class="font-pt-mono text-2xl mb-3 leading-6">{{ $t('rules_subtitle_4') }}</h2>
        <p class="global-text-sm text-sm mb-6 font-qanelas-light">{{ $t('rules_par_4') }}</p>
      </div>

      <div class="img-block mb-14">
        <picture class="block w-full">
          <img src="/imgs/content/photos/rules3.jpg" alt="Holy Chic Rules" class="block w-full">
        </picture>
      </div>

      <div class="container mb-10">
        <h2 class="font-pt-mono text-2xl mb-3 leading-6">{{ $t('rules_subtitle_5') }}</h2>
        <p class="global-text-sm text-sm mb-3 font-qanelas-light">{{ $t('rules_par_5') }}</p>
        <p class="global-text-sm text-sm mb-3 font-qanelas-light">{{ $t('rules_par_6') }}</p>
        <p class="global-text-sm text-sm mb-3 font-qanelas-light">{{ $t('rules_par_7') }}</p>
        <p class="global-text-sm text-sm font-qanelas-light">{{ $t('rules_par_8') }}</p>
      </div>

      <div class="container mb-10">
        <h2 class="font-pt-mono text-2xl mb-3 leading-6">{{ $t('rules_conditions_1') }}</h2>
        <p class="global-text-sm text-sm mb-3 font-qanelas-light">{{ $t('rules_conditions_2') }}</p>
        <p class="global-text-sm text-sm mb-3 font-qanelas-light">{{ $t('rules_conditions_3') }}</p>
        <ul class="global-text-sm text-sm mb-3 font-qanelas-light">
          <li class="ml-4 mb-2">• {{ $t('rules_conditions_sub_1') }}</li>
          <li class="ml-4 mb-2">• {{ $t('rules_conditions_sub_2') }}</li>
          <li class="ml-4 mb-2">• {{ $t('rules_conditions_sub_3') }}</li>
          <li class="ml-4 mb-2">• {{ $t('rules_conditions_sub_4') }}</li>
          <li class="ml-4 mb-2">• {{ $t('rules_conditions_sub_5') }}</li>
          <li class="ml-4 mb-2">• {{ $t('rules_conditions_sub_6') }}</li>
          <li class="ml-3">• {{ $t('rules_conditions_sub_7') }}</li>
        </ul>
        <p class="global-text-sm text-sm font-qanelas-light">{{ $t('rules_conditions_4') }}</p>
      </div>

      <div class="container mb-10">
        <h2 class="font-pt-mono text-2xl mb-3 leading-6">{{ $t('rules_conditions_5') }}</h2>
        <p class="global-text-sm text-sm mb-3 font-qanelas-light">{{ $t('rules_conditions_6') }}</p>
        <p class="global-text-sm text-sm mb-3 font-qanelas-light">{{ $t('rules_conditions_7') }}</p>
        <p class="global-text-sm text-sm font-qanelas-light">{{ $t('rules_conditions_8') }}</p>
      </div>

      <div class="container mb-10">
        <h2 class="font-pt-mono text-2xl mb-3 leading-6">{{ $t('rules_conditions_9') }}</h2>
        <p class="global-text-sm text-sm mb-3 font-qanelas-light">{{ $t('rules_conditions_10') }}</p>
        <p class="global-text-sm text-sm mb-3 font-qanelas-light">{{ $t('rules_conditions_11') }}</p>
        <p class="global-text-sm text-sm font-qanelas-light">{{ $t('rules_conditions_12') }}</p>
      </div>

      <div class="container mb-10">
        <p class="global-text-sm text-sm font-qanelas-light">{{ $t('rules_conditions_13') }}</p>
      </div>

      <div class="container mb-10">
        <div class="send-photo">
          <div class="send-photo__arrow">
            <picture class="trigger-photo__arrow" >
              <svg viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg" class="child-stroke-red">
                <line x1="0.725647" y1="14.5246" x2="42.3172" y2="14.5246" stroke-width="1.0417"/>
                <line x1="28.3124" y1="28.3901" x2="42.0261" y2="14.6764" stroke-width="1.0417"/>
                <line x1="29.049" y1="1.04064" x2="42.7627" y2="14.7544" stroke-width="1.0417"/>
              </svg>
            </picture>
          </div>

          <a class="send-photo__link block flex justify-center items-center bg-red  rounded-full" href="https://www.instagram.com/holy_chic.md/" target="_blank">
            <span class="text-white font-qanelas-bold uppercase text-center text-xs" v-html="$t('send_photos')"></span>
          </a>

          <div class="send-photo__arrow">
            <picture class="trigger-photo__arrow" >
              <svg viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg" class="child-stroke-red origin-center rotate-180 transform">
                <line x1="0.725647" y1="14.5246" x2="42.3172" y2="14.5246" stroke-width="1.0417"/>
                <line x1="28.3124" y1="28.3901" x2="42.0261" y2="14.6764" stroke-width="1.0417"/>
                <line x1="29.049" y1="1.04064" x2="42.7627" y2="14.7544" stroke-width="1.0417"/>
              </svg>
            </picture>
          </div>

        </div>
      </div>
    </section>


    <section class="bg-red text-white py-10 mb-8">
      <div class="container">

        <h2 class="font-pt-mono text-xl"><span class="text-7xl tracking-tighter mb-3">25</span>%</h2>
        <p class="global-text-sm text-sm  mb-5" v-html="$t('from_final_cost_br')"></p>

        <h2 class="font-pt-mono text-xl"><span class="text-7xl tracking-tighter mb-3">12</span>%</h2>
        <p class="global-text-sm text-sm  mb-9" v-html="$t('income_tax_br')"></p>

        <p class="text-xs half-bordered-paragraph half-bordered-paragraph--white half-bordered-paragraph--small-p xl:text-sm">
          {{ $t('income_tax_description') }}
        </p>

      </div>

    </section>


    <section id="formula">
      <div class="container" >
        <h2 class="font-pt-mono text-2xl mb-6 text-center">{{ $t('formula') }}</h2>
        <picture class="block w-full mb-14 formula-img mx-auto">
          <img :src="`/imgs/formula_${$i18n.locale}.svg`" alt="Holy Chic Formula" class="block w-full">
        </picture>

        <router-link to="/brands" class="trigger-link mb-8">
          <span class="trigger-link__link-text text-red" v-html="$t('work_brands')"></span>
          <picture class="trigger-link__arrow" >
            <svg viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg" class="child-stroke-red">
              <line x1="0.725647" y1="14.5246" x2="42.3172" y2="14.5246" stroke-width="1.0417"/>
              <line x1="28.3124" y1="28.3901" x2="42.0261" y2="14.6764" stroke-width="1.0417"/>
              <line x1="29.049" y1="1.04064" x2="42.7627" y2="14.7544" stroke-width="1.0417"/>
            </svg>
          </picture>
        </router-link>
        <a href="javascript:void(0)" class="trigger-link justify-between mb-12" @click="$router.go(-1)" rel="nofollow">
          <span class="trigger-link__link-text order-2 grow-0 flex-grow-0" v-html="$t('go_back_br')"></span>
          <picture class="trigger-link__arrow order-1 to-back">
            <svg viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg" class="child-stroke-black origin-center rotate-180 transform">
              <line x1="0.725647" y1="14.5246" x2="42.3172" y2="14.5246" stroke-width="1.0417"/>
              <line x1="28.3124" y1="28.3901" x2="42.0261" y2="14.6764" stroke-width="1.0417"/>
              <line x1="29.049" y1="1.04064" x2="42.7627" y2="14.7544" stroke-width="1.0417"/>
            </svg>
          </picture>
        </a>

      </div>
    </section>



  </div>
</template>
